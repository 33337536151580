import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { save, load } from 'redux-localstorage-simple';
import { Provider } from 'react-redux';
import { getProducts } from './redux/actions/productActions';
import rootReducer from './redux/reducers/rootReducer';
import { getBannerText } from './redux/actions/bannerTextActions';
import App from './App';
import './assets/scss/style.scss';
import * as serviceWorker from './serviceWorker';

import { composeWithDevTools } from 'redux-devtools-extension';

const store = createStore(
  rootReducer,
  load({ states: ['paymentData', 'compareData', 'currencyData', 'multilanguage', 'productData'] }),
  composeWithDevTools(applyMiddleware(thunk, save({
    ignoreStates: ['initPointReducer'],
    states: ['paymentData', 'compareData', 'currencyData', 'multilanguage', 'productData'],
  }))),
);

// fetch products from json file
store.dispatch(getProducts());
store.dispatch(getBannerText());

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
