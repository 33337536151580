export const checkIfHasVariants = (item) =>
  item.colors && item.colors.length>0 ||
  item.prints && item.prints.length>0 ||
  item.sizes && item.sizes.length>0 ||
  item.weights && item.weights.length>0;

export const checkIfVariantsMissing = (item, selectedVariants) => {
  if (checkIfHasVariants(item)) {
    return (

      (item.colors &&
        item.colors.length > 0 &&
        selectedVariants &&
        selectedVariants.colorSelected.value === "")||
      (item.sizes &&
        item.sizes.length > 0 &&
        selectedVariants &&
        selectedVariants.sizeSelected.value === "")
      ||
      (item.prints &&
        item.prints.length > 0 &&
        selectedVariants &&
        selectedVariants.printSelected.value === "")
      ||
      (item.weights &&
        item.weights.length > 0 &&
        selectedVariants &&
        selectedVariants.weightSelected.value === "")
    );
  }
  return checkIfHasVariants(item);
};
